import React from 'react'
import Home from './Modules/Componants/Home'

const App = () => {
  return (
   <>
  <Home/>
   
   </>
  )
}

export default App;